import moment from 'moment';
import { OrderTypes } from '../constants/orderTypes';
import {
  getReservationTime,
  sortBusReservations,
  sortFlightReservations,
  sortReservationsByJourney,
} from './orders';
import firebaseApp from '../services/Firebase';
import { getDocumentsFromCollection } from './firebase';
import { getBudgetStatusMessages, getCrucialInfoAboutCard } from './budget';
import {
  getBudgetItemsCategoriesFromArchive,
  getOrderCommonFields,
} from './archive';
import appFunctions from '../services/Functions';
import { getReceiptUrlFromReceipts } from './receipts';

function getFlightAdditionalFields(order) {
  const { journeys = [], reservations = [] } = order;

  const numJourneys = journeys.length;
  const lastJourney = numJourneys > 0 ? journeys[numJourneys - 1] : null;

  const departureDate = moment(journeys[0].departureDate, 'YYYY-MM-DD');
  const createdAt = moment.unix(order.createdAt);
  const sortedReservations = sortReservationsByJourney(
    sortFlightReservations(reservations)
  );
  const flightOutboundTime = getReservationTime(sortedReservations[0]);
  const flightInboundTime = getReservationTime(sortedReservations[1]);

  const { carriers, bases, familyNames, baggages } = reservations.reduce(
    (obj, reservation) => {
      const { base, familyName, baggage = 0 } = reservation.flightDetails.fare;

      obj.bases.push(base);
      obj.familyNames.push(familyName);
      obj.baggages.push(baggage.toString());
      obj.carriers.push(reservation.flightDetails.airline);

      return obj;
    },
    {
      bases: [],
      familyNames: [],
      baggages: [],
      carriers: [],
    }
  );

  return {
    flightCarrier: carriers.join(' / '),
    flightNumJourneys: numJourneys,
    flightJourneys: journeys
      .map(
        ({ departureStation, arrivalStation }) =>
          `${departureStation.iataCode} / ${arrivalStation.iataCode}`
      )
      .join(' / '),
    flightFirstDepartureDate: departureDate.format('DD/MM/YYYY'),
    flightFirstDepartureAirport: journeys[0].departureStation.iataCode,
    flightFirstDepartureCity: journeys[0].departureStation.name,
    flightLastDepartureDate: lastJourney
      ? moment(lastJourney.departureDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
      : null,
    flightLastDepartureAirport: lastJourney
      ? lastJourney.departureStation.iataCode
      : null,
    flightLastDepartureCity: lastJourney
      ? lastJourney.departureStation.name
      : null,
    flightBases: bases.join(', '),
    flightFamilyNames: familyNames.join(', '),
    flightBaggages: baggages.join(', '),
    anticipatedPurchase: departureDate.diff(createdAt, 'days'),
    flightFirstDepartureTime: flightOutboundTime.departure,
    flightFirstArrivalTime: flightOutboundTime.arrival,
    flightLastDepartureTime: flightInboundTime.departure,
    flightLastArrivalTime: flightInboundTime.arrival,
    flightWithCredit: order?.creditUsed ? 'VERDADEIRO' : 'FALSO',
    flightCreditAmount: order.creditUsedAmount
      ? parseFloat(order.creditUsedAmount)
      : 0,
  };
}

function getHotelAdditionalFields(order) {
  const {
    hotel,
    checkIn: rawCheckIn,
    checkOut: rawCheckOut,
    selectedRoom = {},
  } = order.reservations[0].hotelDetails;
  const { destination } = hotel;

  const checkIn = moment(rawCheckIn, 'DD/MM/YYYY');
  const checkOut = moment(rawCheckOut, 'DD/MM/YYYY');
  const createdAt = moment.unix(order.createdAt);

  const total = parseFloat(order.fare.total);

  return {
    hotelName: hotel.name,
    hotelAddress: destination.address,
    hotelCity: destination.city.pt || destination.city.en,
    hotelCheckIn: rawCheckIn,
    hotelCheckOut: rawCheckOut,
    hotelDailyRate: total / (checkOut.diff(checkIn, 'days') || 1),
    hotelHasBreakfast: !!selectedRoom.board?.breakfast,
    hotelBoardDescription: selectedRoom.board?.description,
    hotelAdults: selectedRoom.adults || 1,
    anticipatedPurchase: checkIn.diff(createdAt, 'days'),
  };
}

function getCarAdditionalFields(order) {

  if(!order.reservations[0]){
    return {
    };
  }
  
  const {
    rental,
    name,
    amenities,
    pickUp: rawPickUp,
    pickUpLocation,
    dropOff: rawDropOff,
    dropOffLocation,
  } = order.reservations[0].carDetails;

  const total = parseFloat(order.fare.total);

  const pickUp = moment(rawPickUp, 'DD/MM/YYYY HH:mm');
  const dropOff = moment(rawDropOff, 'DD/MM/YYYY HH:mm');
  const createdAt = moment.unix(order.createdAt);

  return {
    carRental: rental?.name,
    carVehicle: name,
    carType: amenities.type,
    carVehicleClass: amenities.sipp,
    carPickUp: rawPickUp,
    carPickUpAddress: pickUpLocation.address,
    carDropOff: rawDropOff,
    carDropOffLocation: dropOffLocation.address,
    carDailyRate: total / (dropOff.diff(pickUp, 'days') || 1),
    anticipatedPurchase: pickUp.diff(createdAt, 'days'),
  };
}

function getBusAdditionalFields(order) {
  let { journeys = [], reservations } = order;

  if (!Array.isArray(journeys)) {
    let i=0;
    let tempJourneys = [];
    while (journeys[i]) {
      tempJourneys.push(journeys[i]);
      i++;
    }
    journeys = tempJourneys;
  }

  const numJourneys = journeys.length;
  const lastJourney = numJourneys > 0 ? journeys[numJourneys - 1] : null;

  const departureDate = moment(journeys[0].departureDate, 'YYYY-MM-DD');
  const createdAt = moment.unix(order.createdAt);

  const sortedReservations = sortBusReservations(reservations);
  const sortedByJourney = sortReservationsByJourney(reservations, 'busDetails');
  const busOutboundTime = getReservationTime(sortedByJourney[0], 'busDetails');
  const busInboundTime = getReservationTime(sortedByJourney[1], 'busDetails');

  const { carriers, classes } = sortedReservations.reduce(
    (obj, reservation) => {
      const { carrier, fare } = reservation.busDetails;

      obj.carriers.push(carrier);
      obj.classes.push(fare.class);

      return obj;
    },
    {
      carriers: [],
      classes: [],
    }
  );

  return {
    busCarriers: carriers.join(' / '),
    busNumJourneys: numJourneys,
    busJourneys: journeys
      .map(
        ({ departureStation, arrivalStation }) =>
          `${departureStation.split(',')[0]} / ${arrivalStation.split(',')[0]}`
      )
      .join(' / '),
    busFirstDepartureDate: departureDate.format('DD/MM/YYYY'),
    busFirstDepartureDate: departureDate.format('DD/MM/YYYY'),
    busLastDepartureDate: lastJourney
      ? moment(lastJourney.departureDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
      : null,
    busClasses: classes.join(' / '),
    anticipatedPurchase: departureDate.diff(createdAt, 'days'),
    busFirstDepartureTime: busOutboundTime.departure,
    busFirstArrivalTime: busOutboundTime.arrival,
    busLastDepartureTime: busInboundTime.departure,
    busLastArrivalTime: busInboundTime.arrival,
  };
}

const ADDITIONAL_FUNCTIONS = {
  [OrderTypes.FLIGHT]: getFlightAdditionalFields,
  [OrderTypes.HOTEL]: getHotelAdditionalFields,
  [OrderTypes.CAR]: getCarAdditionalFields,
  [OrderTypes.BUS]: getBusAdditionalFields,
};

export function getAdditionalOrderFields(order) {
  const func = ADDITIONAL_FUNCTIONS[order.type];

  return func ? func(order) : {};
}

export async function getDefaultOrderFields({
  order,
  messages,
  getDataFromArchive,
}) {
  const {
    orderId,
    type,
    passenger = {},
    requestor,
    approvers = [],
    whoApproved = [],
    createdAt,
    costCenter = {},
    project = {},
    motive = {},
    tags = [],
    observation,
    esg = {},
    statusHistory = [],
    fare = {},
    bookingCode,
    bookingTicket,
    cheapestAlternative,
    creditCard = {},
    installments,
  } = order;

  const getAlternativeInfo = () => {
    if (cheapestAlternative) {
      const cheapestAlternativeTotal = parseFloat(
        cheapestAlternative.fare.total || 0
      );
      const total = parseFloat(fare.total || 0);

      return {
        cheapestAlternative: cheapestAlternativeTotal,
        alternativeDifference: total - cheapestAlternativeTotal,
        alternativeDifferencePercentage: total / cheapestAlternativeTotal - 1,
      };
    }

    return {
      cheapestAlternative: 0,
      alternativeDifference: 0,
      alternativeDifferencePercentage: 0,
    };
  };

  const commonFields = await getOrderCommonFields(order, getDataFromArchive);

  return {
    id: orderId,
    type: messages[`travel.menu.${type}`],
    passengerName: `${passenger?.firstName} ${passenger?.lastName}`,
    passengerEmail: passenger?.email,
    requestorName: requestor
      ? `${requestor.firstName} ${requestor.lastName}`
      : '',
    requestorEmail: requestor ? requestor.email : '',
    approvers: approvers.join(', '),
    whoApproved: whoApproved.join(', '),
    createdAt: moment.unix(createdAt).format('DD/MM/YYYY HH:mm'),
    costCenter: costCenter?.label,
    costCenterInternalId: commonFields.costCenter?.externalId,
    project: project?.label,
    projectInternalId: commonFields.project?.externalId,
    motive: motive?.label,
    motiveInternalId: commonFields.motive?.externalId,
    tags: Array.isArray(tags)
      ? tags
          .map(({ label = '' }) => label)
          .filter((x) => x)
          .join(', ')
      : '',
    observation,
    esgCo2: esg?.carbon_kg || 0,
    esgKm: esg?.distance_km || 0,
    issueType: statusHistory.find(({ task }) => task === 'MANUAL_ISSUE')
      ? 'OFFLINE'
      : 'ONLINE',
    fare: parseFloat(fare.fare || 0),
    taxes: parseFloat(fare.taxes || 0),
    additional: parseFloat(fare.additional || 0),
    total: parseFloat(fare.total || 0),
    bookingCode,
    bookingTicket,
    creditCardId: creditCard?.id,
    creditCardName: creditCard?.name,
    creditCardInstallments: installments,
    ...getAlternativeInfo(),
  };
}

export async function setOrdersToReportModel({
  orders,
  messages,
  getDataFromArchive,
}) {
  return await Promise.all(
    orders.map(async (order) => {
      return {
        ...(await getDefaultOrderFields({
          order,
          messages,
          getDataFromArchive,
        })),
        ...getAdditionalOrderFields(order),
      };
    })
  );
}

export async function getDefaultBudgetFields({
  budget,
  cards,
  messages,
  getDataFromArchive = () => null,
  params = {},
}) {
  const { substitution = '-', dateFormat = 'DD/MM/YY' } = params;

  const { passengerName } = budget.filter;
  const card = cards[budget.cardId];

  const createdAt = moment.unix(budget.createdAt);
  const startDate = moment(budget.startDate, 'YYYY-MM-DD');
  const anticipationDate = startDate.diff(createdAt, 'days');
  const statusMessages = getBudgetStatusMessages(budget);

  const commonFields = await getOrderCommonFields(budget, getDataFromArchive);

  return {
    id: budget.id,
    name: budget.name,
    passengerName,
    cardType: messages[`refund.card-type.${budget.cardType.toLowerCase()}`],
    budgetCategory: budget.category?.label || substitution,
    createdAt: createdAt.format(dateFormat),
    startDate: moment(budget.startDate, 'YYYY-MM-DD').format(dateFormat),
    endDate: moment(budget.endDate, 'YYYY-MM-DD').format(dateFormat),
    anticipationDate: anticipationDate >= 0 ? anticipationDate : 0,
    budgetValue: budget.budget || 0,
    total: budget.fare.total,
    wasApproved: !!budget.wasApproved,
    cardId: budget.cardId,
    cardInfo: getCrucialInfoAboutCard(card, messages, substitution),
    costCenter: budget.costCenter?.label,
    costCenterInternalId: commonFields.costCenter?.externalId,
    project: budget.project?.label,
    projectInternalId: commonFields.project?.externalId,
    motive: budget.motive?.label,
    motiveInternalId: commonFields.motive?.externalId,
    tags: Array.isArray(budget.tags)
      ? budget.tags
          .map(({ label = '' }) => label)
          .filter((x) => x)
          .join(', ')
      : '',
    observation: budget.observation,
    statusCodeMessage: messages[statusMessages[budget.statusCode]] || '',
    commonFields,
  };
}

const getItemsFromBudget = (budgetId) =>
  getDocumentsFromCollection(() =>
    firebaseApp.getItemsFromBudget(budgetId).get()
  )
    .then((items) => items.filter((item) => item.name && !item.refunded))
    .catch(() => []);

export async function getBudgetItemsFields({
  budget,
  messages,
  params,
  getDataFromArchive = () => null,
  organizationId,
  receipts = {},
}) {
  const { substitution = '-', dateFormat = 'DD/MM/YY' } = params;
  const items = await getItemsFromBudget(budget.id);
  const categories = await getBudgetItemsCategoriesFromArchive(
    items,
    organizationId,
    getDataFromArchive
  );

  return items.map(
    ({ id, name, date, fare = {}, type, category, invoice }) => ({
      ...budget,
      itemId: id,
      itemName: name || substitution,
      itemDate: moment(date).format(dateFormat),
      itemTotal: fare.total || 0,
      itemCategory: category ? category.label : substitution,
      itemCategoryInternalId: categories[category?.value]?.externalId,
      itemType:
        messages[`reports.excel.budget.item-type.${type}`] ||
        messages['reports.excel.budget.item-type.items'],
      invoiceId: invoice?.card_transaction?.external_id,
      receiptLink: getReceiptUrlFromReceipts({
        budgetId: budget.id,
        budgetItemId: id,
        receipts,
      }),
    })
  );
}

export async function setBudgetsToReportModel(
  budgets,
  cards,
  messages,
  getDataFromArchive,
  params = {}
) {
  const receipts = await (params?.withItems
    ? appFunctions
        .getReceiptUrlsFromBudgets(budgets.map(({ id }) => id))
        .catch((err) => {})
    : {});

  const fields = await Promise.all(
    budgets.map(async (budget) => {
      const defaultFields = await getDefaultBudgetFields({
        budget,
        cards,
        messages,
        getDataFromArchive,
        params,
      });

      return params?.withItems
        ? getBudgetItemsFields({
            budget: defaultFields,
            messages,
            params,
            getDataFromArchive,
            organizationId: budget.organizationId,
            receipts,
          })
        : [defaultFields];
    })
  );

  return fields.flat();
}
