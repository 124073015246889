import React from 'react';
import { ReactComponent as RoundTripArrow } from '../../assets/img/icons/roundtrip.svg';
import { ReactComponent as OneWayArrow } from '../../assets/img/icons/oneway.svg';
import { normalizeCity } from '../../utils/city';

export default function TripInfo(props) {
  let { journeys } = props;

  if (!Array.isArray(journeys)) {
    let i=0;
    let tempJourneys = [];
    while (journeys[i]) {
      tempJourneys.push(journeys[i]);
      i++;
    }
    journeys = tempJourneys;
  }

  const firstStation = journeys[0].departureStation;
  const lastStation = journeys[journeys.length - 1].arrivalStation;

  const getJourneyComponent = () => {
  if (firstStation === lastStation) {
      const middleJourney = journeys[journeys.length / 2 - 1];

      return (
        <>
          <div className="text-right">{normalizeCity(firstStation)}</div>
          <RoundTripArrow className="round-trip-arrow" />
          {normalizeCity(middleJourney.arrivalStation)}
        </>
      );
    } else {
      return (
        <>
          <div className="text-right">{normalizeCity(firstStation)}</div>
          <OneWayArrow className="one-way-arrow" />
          {normalizeCity(lastStation)}
        </>
      );
    }
  };


  return (
    <div className="trip-info d-flex justify-content-center align-items-center text-dark">
      {getJourneyComponent()}
    </div>
  );
}
