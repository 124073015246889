import moment from 'moment';
import { OrderTypes } from '../constants/orderTypes';
import { generateXLSX } from './fileGeneration';
import { getCurrencyAndPrice } from './money';
import { pdf } from '@react-pdf/renderer';
import ReportsPDF from '../components/reports/pdf/ReportsPDF';
import BudgetPDF from '../components/reports/pdf/BudgetPDF';
import { getDocumentsFromCollection } from './firebase';
import firebaseApp from '../services/Firebase';
import { getOrganizationConfig } from '../utils/organization';
import { getCrucialInfoAboutCard } from './budget';
import {
  setBudgetsToReportModel,
  setOrdersToReportModel,
} from './reportsFormat';
import {
  BUDGET_ITEMS_REPORTS_FIELDS,
  BUDGET_REPORTS_FIELDS,
  ORDER_REPORTS_FIELDS_DETAILED,
  ORDER_REPORTS_FIELDS_SIMPLE,
} from '../constants/reports';

export const REPORT_PRINT_TYPES = {
  SIMPLE: 1,
  DETAILED: 2,
};

export const REPORT_FILE_TYPES = [
  {
    value: 'pdf',
    label: '.pdf',
    allows: [REPORT_PRINT_TYPES.DETAILED],
  },
  {
    value: 'xlsx',
    label: '.xlsx',
    allows: [REPORT_PRINT_TYPES.DETAILED, REPORT_PRINT_TYPES.SIMPLE],
  },
  {
    value: 'csv',
    label: '.csv',
    allows: [REPORT_PRINT_TYPES.DETAILED, REPORT_PRINT_TYPES.SIMPLE],
  },
];

export const EXPENSE_PRINT_TYPES = {
  SIMPLE: 1,
  BY_CARDS: 2,
  BY_ITEMS: 3,
};

export const EXPENSE_FILE_TYPES = [
  {
    value: 'pdf',
    label: '.pdf',
    allows: [EXPENSE_PRINT_TYPES.SIMPLE, EXPENSE_PRINT_TYPES.BY_CARDS],
  },
  {
    value: 'xlsx',
    label: '.xlsx',
    allows: [EXPENSE_PRINT_TYPES.SIMPLE, EXPENSE_PRINT_TYPES.BY_ITEMS],
  },
  {
    value: 'csv',
    label: '.csv',
    allows: [EXPENSE_PRINT_TYPES.SIMPLE, EXPENSE_PRINT_TYPES.BY_ITEMS],
  },
];

export const STATEMENT_FILE_TYPES = [
  {
    value: 'pdf',
    label: '.pdf',
  },
  {
    value: 'ofx',
    label: '.ofx',
  },
  {
    value: 'csv',
    label: '.csv',
  },
  {
    value: 'xlsx',
    label: '.xlsx',
  },
];

export function generateColumns(ids, messages, messagesPrefix = '') {
  const _prefix = messagesPrefix ? `${messagesPrefix}.` : '';

  const columns = ids.map((id) => ({
    header: messages[`${_prefix}${id}`],
    id,
    width: 20,
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
  }));

  columns[0].style = { alignment: { vertical: 'middle', horizontal: 'left' } };
  columns[columns.length - 1].style = {
    alignment: { vertical: 'middle', horizontal: 'right' },
  };

  return columns;
}

const formatFlightContent = (
  firstJourney,
  lastJourney,
  isRoundTrip,
  detailed
) => {
  const symbol = isRoundTrip ? '<>' : '>';

  if (detailed) {
    return `(${firstJourney.departureStation.iataCode}) ${firstJourney.departureStation.name} ${symbol} (${lastJourney.arrivalStation.iataCode}) ${lastJourney.arrivalStation.name}`;
  } else {
    return `${firstJourney.departureStation.iataCode} ${symbol} ${lastJourney.arrivalStation.iataCode}`;
  }
};

const formatBusContent = (firstJourney, lastJourney, isRoundTrip) => {
  return `${firstJourney?.departureStation} ${isRoundTrip ? '<>' : '>'} ${
    lastJourney?.arrivalStation
  }`;
};

const getJourneyContent = (order, formatFunction, detailed) => {
  const { roundTrip, journeys } = order.filter;

  const firstJourney = journeys[0];
  const lastJourney =
    journeys[roundTrip ? journeys.length / 2 - 1 : journeys.length - 1];

  return formatFunction(firstJourney, lastJourney, roundTrip, detailed);
};

const getFlightContent = (order, detailed) => {
  return getJourneyContent(order, formatFlightContent, detailed);
};

const getHotelContent = (order, detailed) => {
  return detailed
    ? `${order.filter.hotelName} (${order.filter.city || ''})`
    : `${order.filter.hotelName}`;
};

const getCarContent = (order) => {
  return order.filter.carName;
};

const getBusContent = (order) => {
  return getJourneyContent(order, formatBusContent);
};

const getOfficeContent = (order, detailed) => {
  return detailed && order.filter.selectedRoom?.name
    ? `${order.filter.officeName} (${order.filter.selectedRoom.name})`
    : order.filter.officeName;
};

const RESERVATION_CONTENT_FUNCTIONS = {
  [OrderTypes.FLIGHT]: getFlightContent,
  [OrderTypes.HOTEL]: getHotelContent,
  [OrderTypes.CAR]: getCarContent,
  [OrderTypes.BUS]: getBusContent,
  [OrderTypes.OFFICE]: getOfficeContent,
};

const getReservationContentFromOrder = (order, detailed) => {
  const func = RESERVATION_CONTENT_FUNCTIONS[order.type];
  return func ? func(order, detailed) : '';
};

export const getArrayFromOrders = (
  orders,
  detailed,
  substitution = '-',
  formatDate = 'DD/MM/YYYY'
) => {
  return orders.map((order) =>
    detailed
      ? ORDER_REPORTS_FIELDS_DETAILED.map((field) => order[field])
      : ORDER_REPORTS_FIELDS_SIMPLE.map((field) => order[field])
  );
};

export const getOldArrayFromOrders = (
  orders,
  detailed,
  substitution = '-',
  formatDate = 'DD/MM/YYYY'
) => {
  return orders.map((order) => {
    const { passengerName, date, price, startDate, endDate, approvers } =
      order.filter;

    const values = [
      order.type,
      passengerName,
      getReservationContentFromOrder(order, detailed),
      order.bookingCode,
      order.costCenter?.label || substitution,
      order.project?.label || substitution,
      moment.unix(date).format(formatDate),
      startDate,
      endDate,
      price,
    ];

    if (detailed) values.push(approvers);

    return values;
  });
};

const getItemsFromBudget = (budgetId) =>
  getDocumentsFromCollection(() =>
    firebaseApp.getItemsFromBudget(budgetId).get()
  )
    .then((items) => items.filter((item) => item.name && !item.refunded))
    .catch(() => []);

const getItemsFromBudgets = (budgets) =>
  budgets.map(({ id }) => getItemsFromBudget(id));

const mergeBudgetsWithItems = (
  budgetsArray,
  itemsArray,
  messages,
  substitution = '-',
  dateFormat = 'DD/MM/YYYY'
) => {
  const finalItems = [];

  budgetsArray.forEach((budget, index) => {
    const items = itemsArray[index];
    if (items && items.length > 0) {
      items.forEach(
        ({ id, name, date, fare = {}, type, category, invoice }) => {
          finalItems.push([
            ...budget,
            id,
            name || substitution,
            moment(date).format(dateFormat),
            fare.total || 0,
            category ? category.label : substitution,
            messages[`reports.excel.budget.item-type.${type}`] ||
              messages['reports.excel.budget.item-type.items'],
            invoice?.card_transaction?.external_id,
          ]);
        }
      );
    }
  });

  return finalItems;
};

export const getOldArrayFromBudgets = ({
  budgets,
  cards = {},
  detailed,
  messages,
  substitution = '-',
  formatDate = 'DD/MM/YY',
  withId = false,
}) => {
  return budgets.map((budget) => {
    const { passengerName } = budget.filter;
    const card = cards[budget.cardId];

    const createdAt = moment.unix(budget.createdAt);
    const startDate = moment(budget.startDate, 'YYYY-MM-DD');
    const anticipationDate = startDate.diff(createdAt, 'days');

    const values = [
      budget.name,
      passengerName,
      messages[`refund.card-type.${budget.cardType.toLowerCase()}`],
      budget.category?.label || substitution,
      createdAt.format(formatDate),
      moment(budget.startDate, 'YYYY-MM-DD').format(formatDate),
      moment(budget.endDate, 'YYYY-MM-DD').format(formatDate),
      anticipationDate >= 0 ? anticipationDate : 0,
      budget.budget || 0,
      budget.fare.total,
      !!budget.wasApproved,
      budget.cardId,
      getCrucialInfoAboutCard(card, messages, substitution),
    ];

    return withId ? [budget.id, ...values] : values;
  });
};

export const getArrayFromBudgetsWithItems = async (
  budgets,
  cards,
  messages,
  substitution = '-'
) => {
  const budgetsArray = getArrayFromBudgets({
    budgets,
    cards,
    messages,
    withId: true,
  });

  const itemsArray = await Promise.all(getItemsFromBudgets(budgets));

  return mergeBudgetsWithItems(
    budgetsArray,
    itemsArray,
    messages,
    substitution
  );
};

export const getArrayFromBudgets = (budgets, fields = []) => {
  return budgets.map((budget) => fields.map((field) => budget[field]));
};

export const replaceProductRowWithMessage = (
  csv,
  messages,
  prefix = 'travel.menu'
) => {
  for (let i = 0, length = csv.length; i < length; i++) {
    csv[i][0] = messages[`${prefix}.${csv[i][0]}`];
  }

  return csv;
};

export const getFieldsFromData = (data, fields = []) => {
  return data.map((item) => fields.map((field) => item[field]));
};

export function generateExcelFile({
  worksheetName,
  data = [],
  fields = [],
  messages,
  params = {},
}) {
  if (!data.length) return null;

  const { columnWidth = 20, headerIdPrefix = 'reports.excel' } = params;

  const csv = getFieldsFromData(data, fields);

  const columns = fields.map((id) => ({
    header: messages[`${headerIdPrefix}.${id}`],
    id,
    width: columnWidth,
    style: { alignment: { vertical: 'middle', horizontal: 'center' } },
  }));

  columns[0].style = {
    alignment: { vertical: 'middle', horizontal: 'left' },
  };
  columns[columns.length - 1].style = {
    alignment: { vertical: 'middle', horizontal: 'right' },
  };

  return generateXLSX(worksheetName, columns, csv);
}

export function generateCSV({ data = [], fields = [], messages, params = {} }) {
  const { headerIdPrefix = 'reports.excel' } = params;

  const arr = [
    fields.map((id) => messages[`${headerIdPrefix}.${id}`]).join(','),
  ];

  getFieldsFromData(data, fields).forEach((item) => {
    arr.push(item.map((value = '') => `${value}`.replace(',', ' ')).join(','));
  });

  return new Blob([arr.join('\n')], { type: 'text/csv' });
}

export const getExcelFromOrders = (
  orders,
  isDetailed,
  worksheetName,
  currency,
  messages
) => {
  return generateExcelFile({
    worksheetName,
    data: orders,
    fields: isDetailed
      ? ORDER_REPORTS_FIELDS_DETAILED
      : ORDER_REPORTS_FIELDS_SIMPLE,
    messages,
  });
};

export const getExcelFromBudgets = (
  budgets,
  withItems,
  worksheetName,
  currency,
  messages
) => {
  return generateExcelFile({
    worksheetName,
    data: budgets,
    fields: withItems
      ? [...BUDGET_REPORTS_FIELDS, ...BUDGET_ITEMS_REPORTS_FIELDS]
      : BUDGET_REPORTS_FIELDS,
    messages,
    params: {
      headerIdPrefix: 'reports.excel.budget',
    },
  });
};

export const getExcelFromMessages = ({ name, rows }) => {
  const columns = [
    {
      header: 'ID',
      id: 'name',
      width: 50,
      style: { alignment: { vertical: 'middle', horizontal: 'left' } },
    },
    {
      header: 'Mensagem',
      id: 'user',
      width: 200,
      style: { alignment: { vertical: 'middle', horizontal: 'left' } },
    },
  ];

  return generateXLSX(name, columns, rows);
};

export const getExcelFromBudgetsByItems = async (
  budgets,
  cards,
  worksheetName,
  currency,
  messages
) => {
  if (budgets.length) {
    const [currencySymbol, _] = getCurrencyAndPrice(10, currency);
    const csv = await getArrayFromBudgetsWithItems(budgets, cards, messages);

    const columns = [
      {
        header: messages['reports.excel.budget.budget-id'],
        id: 'budget-id',
        width: 25,
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
      },
      {
        header: messages['reports.excel.budget.budget-name'],
        id: 'name',
        width: 25,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      },
      {
        header: messages['reports.excel.budget.user'],
        id: 'user',
        width: 35,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      },
      {
        header: messages['reports.excel.budget.payment-type'],
        id: 'type',
        width: 10,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      },
      {
        header: messages['reports.excel.budget.category'],
        id: 'category',
        width: 15,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      },
      {
        header: messages['reports.excel.budget.created-at'],
        id: 'startDate',
        width: 20,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      },
      {
        header: messages['reports.excel.budget.start-date'],
        id: 'startDate',
        width: 20,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      },
      {
        header: messages['reports.excel.budget.end-date'],
        id: 'endDate',
        width: 20,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      },
      {
        header: messages['reports.excel.budget.anticipation-date'],
        id: 'endDate',
        width: 20,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      },
      {
        header: `${messages['reports.excel.budget.budget']} (${currencySymbol})`,
        id: 'budget',
        width: 20,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      },
      {
        header: `${messages['reports.excel.total']} (${currencySymbol})`,
        id: 'total',
        width: 20,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      },
      {
        header: messages['reports.excel.budget.was-approved'],
        id: 'wasApproved',
        width: 15,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      },
      {
        header: messages['reports.excel.budget.card-id'],
        id: 'cardId',
        width: 25,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      },
      {
        header: messages['reports.excel.budget.card-info'],
        id: 'cardInfo',
        width: 35,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      },
      {
        header: messages['reports.excel.budget.item-id'],
        id: 'itemId',
        width: 25,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      },
      {
        header: messages['reports.excel.budget.item-name'],
        id: 'itemName',
        width: 35,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      },
      {
        header: messages['reports.excel.budget.item-date'],
        id: 'itemDate',
        width: 20,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      },
      {
        header: `${messages['reports.excel.budget.item-total']} (${currencySymbol})`,
        id: 'itemTotal',
        width: 20,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      },
      {
        header: messages['reports.excel.budget.item-category'],
        id: 'itemCategory',
        width: 35,
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
      },
      {
        header: messages['reports.excel.budget.item-type'],
        id: 'itemType',
        width: 15,
        style: { alignment: { vertical: 'middle', horizontal: 'right' } },
      },
      {
        header: messages['reports.excel.budget.reference-id'],
        id: 'referenceId',
        width: 15,
        style: { alignment: { vertical: 'middle', horizontal: 'left' } },
      },
    ];

    return generateXLSX(worksheetName, columns, csv);
  } else return null;
};

export const getPDFFromOrders = (
  orders,
  isDetailed,
  organization,
  messages,
  dateSelection
) => {
  return pdf(
    ReportsPDF(
      getOldArrayFromOrders(orders, isDetailed, '', 'DD MMM YY'),
      organization,
      dateSelection,
      messages,
      isDetailed
    )
  ).toBlob();
};

export const getPDFFromBudgets = async (
  budgets,
  cards,
  isDetailed,
  organization,
  messages,
  dateSelection
) => {
  const budgetPdf = await BudgetPDF({
    items: getOldArrayFromBudgets({
      budgets,
      cards,
      detailed: isDetailed,
      messages,
    }),
    cards,
    organization,
    dateSelection,
    messages,
    isDetailed,
  });

  return pdf(budgetPdf).toBlob();
};

export const getJSONFromOrders = (
  orders,
  isDetailed,
  keys,
  messages,
  ident = 2
) => {
  const obj = orders.reduce((finalObj, order, index) => {
    const { passengerName, date, price, startDate, endDate, approvers } =
      order.filter;

    const orderObj = {
      [keys.type]: messages[`travel.menu.${order.type}`].toLowerCase(),
      [keys.passenger]: passengerName,
      [keys.reservation]: getReservationContentFromOrder(order, isDetailed),
      [keys.date]: moment.unix(date).format('DD/MM/YYYY'),
      [keys.startDate]: startDate,
      [keys.endDate]: endDate,
      [keys.approvers]: approvers,
      [keys.total]: price,
    };

    if (order.costCenter?.label) {
      orderObj[keys.costCenter] = order.costCenter.label;
    }

    if (order.project?.label) {
      orderObj[keys.project] = order.project.label;
    }

    finalObj[index + 1] = orderObj;
    return finalObj;
  }, {});

  return new Blob([JSON.stringify(obj, null, ident)], {
    type: 'application/json',
  });
};

export const getJSONFromBudgets = (
  orders,
  isDetailed,
  keys,
  messages,
  ident = 2
) => {
  const obj = orders.reduce((finalObj, order, index) => {
    const { passengerName, date, price, startDate, endDate, approvers } =
      order.filter;

    const orderObj = {
      [keys.name]: order.name,
      [keys.userName]: passengerName,
      [keys.cardType]:
        messages[`refund.card-type.${order.cardType.toLowerCase()}`],
      [keys.category]: order.category?.label || '-',
      [keys.startDate]: startDate,
      [keys.endDate]: endDate,
      [keys.budget]: order.budget,
      [keys.total]: order.fare.total,
      [keys.wasApproved]: order.wasApproved,
      [keys.cardId]: order.cardId,
    };

    if (order.costCenter?.label) {
      orderObj[keys.costCenter] = order.costCenter.label;
    }

    if (order.project?.label) {
      orderObj[keys.project] = order.project.label;
    }

    finalObj[index + 1] = orderObj;
    return finalObj;
  }, {});

  return new Blob([JSON.stringify(obj, null, ident)], {
    type: 'application/json',
  });
};

export const getCSVFromOrders = (orders, detailed, messages) => {
  return generateCSV({
    data: orders,
    fields: detailed
      ? ORDER_REPORTS_FIELDS_DETAILED
      : ORDER_REPORTS_FIELDS_SIMPLE,
    messages,
  });
};

export const getCSVFromBudgets = (budgets, withItems, messages) => {
  const fields = withItems
    ? [...BUDGET_REPORTS_FIELDS, ...BUDGET_ITEMS_REPORTS_FIELDS]
    : BUDGET_REPORTS_FIELDS;

  return generateCSV({
    data: budgets,
    fields,
    messages,
    params: {
      headerIdPrefix: 'reports.excel.budget',
    },
  });
};

export const getKeysFromReportJSON = (messages) => {
  return {
    type: messages['reports.json.type'],
    passenger: messages['reports.json.passenger'],
    reservation: messages['reports.json.reservation'],
    costCenter: messages['reports.json.cost-center'],
    project: messages['reports.json.project'],
    date: messages['reports.json.creation-date'],
    startDate: messages['reports.json.start-date'],
    endDate: messages['reports.json.end-date'],
    approvers: messages['reports.json.approvers'],
    total: messages['reports.json.total'],
  };
};

export const getKeysFromBudgetJSON = (messages) => {
  return {
    name: messages['reports.excel.budget.budget-name'],
    userName: messages['reports.excel.budget.user'],
    cardType: messages['reports.excel.budget.payment-type'],
    category: messages['reports.excel.budget.category'],
    startDate: messages['reports.excel.budget.start-date'],
    endDate: messages['reports.excel.budget.end-date'],
    budget: messages['reports.excel.budget.budget'],
    total: messages['reports.excel.total'],
    wasApproved: messages['reports.excel.budget.was-approved'],
    cardId: messages['reports.excel.budget.card-id'],
  };
};

export const generateReportFile = async ({
  orders = [],
  type,
  isDetailed,
  organization,
  messages,
  dateSelection,
  getDataFromArchive,
}) => {
  if (orders.length) {
    const reportOrders = await setOrdersToReportModel({
      orders,
      messages,
      getDataFromArchive,
    });

    if (type === 'pdf')
      return getPDFFromOrders(
        orders,
        isDetailed,
        organization,
        messages,
        dateSelection
      );
    else if (type === 'xlsx') {
      let worksheetName = messages['reports.excel.worksheet-name'];

      if (dateSelection.mode === 'year') {
        worksheetName = moment.unix(dateSelection.startDate).format('YYYY');
      } else if (
        dateSelection.mode === 'month' ||
        dateSelection.mode === 'custom'
      ) {
        worksheetName = `${moment
          .unix(dateSelection.startDate)
          .format('DD-MM-YYYY')} | ${moment
          .unix(dateSelection.endDate)
          .format('DD-MM-YYYY')}`;
      }

      return getExcelFromOrders(
        reportOrders,
        isDetailed,
        worksheetName,
        getOrganizationConfig(organization.config, 'currency'),
        messages
      );
    } else if (type === 'csv')
      return getCSVFromOrders(reportOrders, isDetailed, messages);
    else {
      const keys = getKeysFromReportJSON(messages);
      return getJSONFromOrders(orders, isDetailed, keys, messages);
    }
  } else return null;
};

export const generateBudgetReportFile = async ({
  budgets = [],
  cards,
  type,
  details = EXPENSE_PRINT_TYPES.SIMPLE,
  organization,
  messages,
  dateSelection,
  getDataFromArchive,
}) => {
  if (budgets.length) {
    const reportBudgets = await setBudgetsToReportModel(
      budgets,
      cards,
      messages,
      getDataFromArchive,
      {
        withItems: details === EXPENSE_PRINT_TYPES.BY_ITEMS,
      }
    );

    const isDetailed = details === EXPENSE_PRINT_TYPES.BY_CARDS;

    if (type === 'pdf') {
      return await getPDFFromBudgets(
        budgets,
        cards,
        isDetailed,
        organization,
        messages,
        dateSelection
      );
    } else if (type === 'xlsx') {
      let worksheetName = messages['reports.excel.worksheet-name'];

      if (dateSelection.mode === 'year') {
        worksheetName = moment.unix(dateSelection.startDate).format('YYYY');
      } else if (
        dateSelection.mode === 'month' ||
        dateSelection.mode === 'custom'
      ) {
        worksheetName = `${moment
          .unix(dateSelection.startDate)
          .format('DD-MM-YYYY')} | ${moment
          .unix(dateSelection.endDate)
          .format('DD-MM-YYYY')}`;
      }

      return getExcelFromBudgets(
        reportBudgets,
        details === EXPENSE_PRINT_TYPES.BY_ITEMS,
        worksheetName,
        getOrganizationConfig(organization.config, 'currency'),
        messages
      );
    } else if (type === 'csv') {
      return getCSVFromBudgets(
        reportBudgets,
        details === EXPENSE_PRINT_TYPES.BY_ITEMS,
        messages
      );
    } else {
      const keys = getKeysFromBudgetJSON(messages);
      return getJSONFromOrders(budgets, isDetailed, keys, messages);
    }
  } else return null;
};

export async function generateInvalidUsersExcel(users, messages) {
  const csv = users
    .sort((a, b) =>
      `${a.firstName} ${a.lastName}`.localeCompare(
        `${b.firstName} ${b.lastName}`
      )
    )
    .map(({ firstName, lastName, cpf, email, invalidMotive }) => [
      firstName,
      lastName,
      cpf,
      email,
      messages[`invalid-users.motives.${invalidMotive}`],
    ]);

  const columns = generateColumns(
    ['first-name', 'last-name', 'cpf', 'email', 'invalid-motive'],
    messages,
    'invalid-users.columns'
  );

  return generateXLSX(messages['invalid-users.xlsx.name'], columns, csv);
}
